.query-builder {
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 15px 0;

  .group {
    background: none;
    border: 1px solid #e4e9f0;
  }

  .group--children > .group-or-rule-container > .group-or-rule::before,
  .group--children > .group-or-rule-container > .group-or-rule::after {
    border-color: #e4e9f0;
  }
  .group--children > .group-or-rule-container > .group-or-rule::after,
  .group--children > .group-or-rule-container > .group-or-rule:before {
    border-width: 0 0 1px 1px;
  }

  .ant-input-number-focused,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #50305f;
    box-shadow: 0 0 0 2px rgba(80, 48, 95, 0.2);
  }

  .ant-select:hover .ant-select-selection {
    border-color: #50305f;
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: rgba(80, 48, 95, 0.2) !important;
    background-color: red;
  }

  input,
  select {
    outline: 0;

    &:focus {
      border-color: #50305f;
    }
  }

  .action--ADD-GROUP {
    display: none;
  }
  .rule--widget--SELECT {
    //display:none;
    .anticon {
      display: none;
    }
  }
  .rule--widget--BOOLEAN {
    //display:none;
    .anticon {
      display: none;
    }
  }

  > div {
    > div {
      > .group--header {
        .group--conjunctions {
          display: none;
        }

        .group--actions {
          opacity: 1 !important;
          .ant-btn-group {
            button:first-child {
              display: none;
            }
            .action--ADD-GROUP {
              display: inline-block;
            }
          }
        }
      }

      > .group--children {
        > div {
          border: 10px solid #77215e !important;
          padding: 10px;
          margin: 10px 0 10px 0;
        }
      }
      .group--children {
        .action--ADD-GROUP {
          display: inline-block;
          span {
            font-size: 0 !important;
            &:before {
              content: 'Add group';
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.MuiAccordionSummary-root {
  background-color: #7c486e !important;
  color: #fff !important;
  > .MuiAccordionSummary-expandIcon {
    color: #fff !important;
  }
}

.group--actions {
  opacity: 1 !important;
}

.my-grid>* {
  float: left;
  overflow: hidden;
}
.my-grid-col-2 {
  width: 50%;
}
.my-grid-col-3 {
  width: 33.33%;
}
.my-grid-col-4 {
  width: 25%;
}
.my-grid:after {
  content: '';
  display: table;
  clear: both;
}
.grid-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.grid-square {
  aspect-ratio: 1;
}
.fill {
  width: 100%;
  height: 100%;
}
.border {
  border: 1px solid #ccc;
  box-sizing: border-box;
}